<template>
  <div>
    <footer class="uc-footer" style="background-color: black">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-left">
            <div class="uc-subtitle color-white">Auspician</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 col-3 mb-16 d-flex align-items-center">
            <a href="https://www.trial.cl/" target="_blank">
              <img
                src="../assets/sponsors/logo_trial.png"
                alt="TRIAL Chile"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="col-lg-2 col-3 mb-16 d-flex align-items-center">
            <a href="https://pilot.cl/" target="_blank">
              <img
                src="../assets/sponsors/logo_pilot.png"
                alt="Pilot"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="col-lg-2 col-3 mb-16 d-flex align-items-center">
            <a href="https://www.nescafe.com/cl/" target="_blank">
              <img
                src="../assets/sponsors/logo_nescafe_2025.png"
                alt="Nescafé"
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
    <footer class="uc-footer">
      <div class="container" style="padding-bottom: 20px !important">
        <div class="row">
          <div class="col-lg-6 text-left">
            <div class="uc-subtitle color-white">Organizan</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-5 mb-16 d-flex align-items-center">
            <a href="https://derecho.uc.cl" target="_blank">
              <img
                src="../assets/logo-derecho.png"
                alt="Facultad de Derecho Universidad Católica de Chile"
                class="img-fluid"
                style="width: 100%"
              />
            </a>
          </div>
          <div class="col-lg-2 col-3 mb-16 d-flex align-items-center">
            <a href="https://redes.derecho.uc.cl/" target="_blank">
              <img
                src="../assets/logo_redes_blanco.png"
                alt="Redes Derecho UC"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="col-lg-2 col-3 mb-16">
            <a href="https://www.cadeuc.cl/" target="_blank">
              <img
                src="../assets/logo-consejeria.png"
                alt="CADE UC"
                class="img-fluid"
                style="width: 70%"
              />
            </a>
          </div>
          <div class="col-lg-5 text-center text-lg-right">
            <div class="mb-16"></div>
            <div class="uc-subtitle color-white mb-16">Redes Derecho UC</div>
            <div class="mb-16">
              <i class="uc-icon">email</i>&nbsp;
              <a
                href="mailto:redesderechouc@uc.cl"
                target="_blank"
                class="external"
                >redesderechouc@uc.cl</a
              >
            </div>
            <Social class="uc-footer_social" />
          </div>
        </div>
      </div>
      <div id="uc-global-footer"></div>
    </footer>
  </div>
</template>

<script>
import Social from "../components/Social.vue";

export default {
  components: {
    Social,
  },
};
</script>

<style lang="sass">
.uc-footer
  padding-top: 60px !important
</style>
